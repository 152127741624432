#offers {

    .offers-wrapper {
        background: #F3E5F5;
        border: 3px solid #E1BEE7;
        margin-top: 12px;
        box-shadow: 0 0 10px rgba(0,0,0,0.1);
        &:hover {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        }

        img {
            max-height: 48px;
            height: auto;
            margin-bottom: 13px;
        }
    }

    .offers-header {
        background: #E1BEE7;
        padding: .7rem;
        font-weight: bold;
        font-size: 1.2rem;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            h2 {
                margin-top: 0;
            }

            a {
                text-decoration: none;
                color: #333;
            }

            padding: 1rem;
            border-bottom: 1px solid #ccc;
            &:last-child {
                border-bottom: none;
            }
            .offer {
                display: flex;
                align-items: center;
                img {
                    width: 50px;
                    height: 50px;
                    margin-right: 1rem;
                }
                h3 {
                    font-size: 1.2rem;
                    margin: 0;
                }
            }
            .price {
                font-size: 1.2rem;
                font-weight: bold;
            }
        }
    }

    p {
        margin: 0 0 .8rem 0;
        line-height: 1.5;
        &:last-child {
            margin-bottom: 0;
        }
    }

    .offer-terms {
        font-size: 0.8rem;
        color: #666;
        margin-top: 0.5rem;

    }

    .offer-domain {
        color: #469124;
    }
}
