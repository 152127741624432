.message {
    border: 1px solid var(--pink);
    background-color: var(--pink-tint);
    padding: 0 16px;
    margin: 12px 0;
    position: relative;
    &.basic {
        background-color: transparent;
        border-color: var(--gray-tint);
    }

    &.info {
        background-color: rgba(225, 245, 254, 0.68);
        border-color: rgba(179, 229, 252, 0.68);
    }

    &.amazon {
        background-color: #fff;
        border: 3px solid #ff9900;
        a.cta {
            background-color: #ff9900;
            color: #fff;
        }
        .logo {
            background: #ffffff;
            img {
                max-width: 100%;
                max-height: 60px;
            }
        }
    }

    > p {
        margin: 0;
        padding: 16px 0;
        font-size: 16px;
        line-height: 1.5;
        color: var(--gray-shade);
    }
}

.message .logo {
    background: #3b3737;
    margin: 16px 0 0;
    padding: 8px;
    line-height: 0;
    text-align: center;
}

.message .logo img {
    max-width: 100%;
}

.message .message-label {
    position: absolute;
    right: 20px;
    bottom: 0;
    color: #b9b9b9;
}

.black-friday {
    margin: 16px 0;
    background: var(--bf-primary-color);
    color: var(--bf-neutral-light);
    padding: 16px;
    transition: max-height 0.3s ease;
    h3 {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 24px;
        font-weight: 900;
    }
    p,
    li {
        font-size: 16px;
    }

    a {
        color: var(--bf-secondary-color) !important;
        &:hover {
            color: var(--bf-hover-secondary) !important;
        }
    }

    li {
        padding: 3px 0;
    }

    p > a {
        color: #fff;
    }

    .disclaimer {
        font-size: 12px;
        color: var(--bf-neutral-dark);
        margin-top: 20px;
    }
    &.contracted {
        max-height: 60px; /* Adjust height as needed */
        overflow: hidden;
        cursor: pointer;
    }
}
