.featured {
  margin: 20px 0;
  background: #FFF8E1;
  border: 1px solid #FFD54F;
  padding: 16px;
  position: relative;
  a {
    color: #FF6F00;
  }

  h3 {
    margin-top: 0;
    font-weight: 600;
  }

  p {
    font-size: 14px;
  }

  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
  &:after {
    content: "Annons";
    font-size: 13px;
    position: absolute;
    right: 20px;
    top: 20px;
    opacity: .45;
  }
}