.we-recommend {
    position: relative;
    background-color: rgba(20, 160, 215, 0.04);
    border-radius: 5px;
    border: 1px solid rgba(20, 160, 215, 0.25);
    margin: 20px 0;
}

.we-recommend:hover {
    border-color: rgba(7, 38, 165, 0.5);
}

.we-recommend:before {
    /* transparent linear gradient at bottom */
    content: "";
    position: absolute;

    left: 0;
    bottom: 0;
    width: 100%;
    height: 140px;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(7, 38, 165, 0.5) 100%);
}

.we-recommend-title {
    position: absolute;
    color: #fff;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    font-size: 18px;
    z-index: 100001;
    left: 20px;
    right: 20px;
    bottom: 20px;
}

.we-recommend-label {
    position: absolute;
    color: #fff;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    font-size: 12px;
    z-index: 100001;
    top: 20px;
    left: 20px;
    background-color: #ef6c00;
    padding: 5px 10px;
    border-radius: 15px;
}
