table {
  width: 100%;
  border: 1px solid var(--nord4);
  border-collapse: collapse;
  margin: 12px 0;
}

thead tr {
    background: var(--nord2);
    color: var(--nord6);
}

th, td {
    padding: 8px;
    border: 1px solid var(--nord4);
  text-align: left;
}

tr:nth-child(even) td {
  background-color: var(--nord5);
}

tr:last-child td {
  border-bottom: none;
}

.table-wrapper {
  display: block;
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-wrapper ~ p {
  margin-top: -6px;
}

td.grow, th.grow {
    width: 100%;
}

caption {
  caption-side: top;
  text-align: left;
  font-size: 18px;
  padding: 8px 8px 8px 0;
  font-weight: 900;
}