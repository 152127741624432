.product {
    border: 1px solid #d4e3c5;
    padding: 16px;
    display: flex;
    background-color: #fcfffb;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.05);
    margin-bottom: 8px;
    position: relative;
    align-items: center;
    &:after {
        content: "Annons";
        position: absolute;
        right: 12px;
        top: 12px;
        font-size: 10px;
        color: var(--nord3);
        text-transform: uppercase;
        opacity: 0.6;
    }
}

.related-image {
    width: 120px;
    height: auto;
    margin-right: 16px;
    align-self: center;
    mix-blend-mode: multiply;
}

.related-data {
    flex-grow: 1;
    align-self: center;
}

.related-title {
    margin-bottom: 0;
    font-size: 18px;
    color: var(--black);
}

.related-description {
    margin-top: 6px;
    font-size: 13px;
    color: var(--gray);
}

.related-link {
    display: inline-block;
    padding: 6px 12px;
    font-size: 14px;
    color: #fff;
    background: var(--blue);
    border-radius: 3px;
}

.props {
    list-style: none;
    margin: 0;
    padding: 8px;
    border: 1px solid var(--nord4);
    li {
        font-size: 14px;
        padding: 4px;
        > strong {
            color: var(--black);
            display: inline-block;
            width: 60px;
        }
    }
}

.tag {
    display: inline-block;
    padding: 2px 4px;
    background: #edfbfd;
    border: 1px solid #d6e9ec;
    border-radius: 3px;
    font-size: 12px;
    color: #0097a7;
    margin-right: 4px;
    margin-bottom: 4px;
    a {
        color: #0097a7;
    }
    &.tag-lg {
        font-size: 16px;
        padding: 6px 12px;
    }
}

.product-link {
    font-size: 14px;
    padding: 20px;
    background-color: #fff;
    margin-bottom: 12px;
    border: 1px solid var(--nord5);
    position: relative;
    img {
        mix-blend-mode: multiply;
    }
    h4 {
        margin-bottom: 16px;
        font-size: 16px;
        font-weight: 600;
        // color: var(--green-shade);
        a {
            color: var(--black-shade);
        }
    }
    p {
        margin-top: 6px;
        font-size: 14px;
        color: var(--black);
    }
    .price-lowered {
        position: absolute;
        color: var(--white);
        background-color: var(--green-shade);
        border-radius: 3px;
        bottom: 12px;
        right: 12px;
        font-size: 12px;
        line-height: 19px;
        padding: 2px 6px;
    }
}

.product-list-ad {
    min-height: 90px;
    max-height: 180px;
    margin-bottom: 12px;
}

.tags {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
}

.product-big {
    margin-bottom: 30px;
    padding-bottom: 30px;
}

.seller {
    max-height: 24px;
    max-width: 100%;
    display: block;
    margin: 0 auto 16px auto;
    filter: grayscale(100%) opacity(0.75);
    .inline {
        margin: 0;
        display: inline;
        max-height: 22px;
    }
}

.product-inline {
    border: 3px solid var(--blue);
    background-color: #fffdf9;

    h4 {
        margin-top: 0;
        font-size: 21px;
        font-weight: 900;
    }
    img {
        mix-blend-mode: multiply;
    }
    p {
        font-size: 14px;
        line-height: 1.4;
        &:last-child {
            margin-bottom: 0;
        }
    }

    .price {
        font-size: 32px;
        font-weight: 600;
        letter-spacing: -0.05em;
        text-align: center;
        small {
            font-weight: 300;
            font-size: 16px;
            color: #9b9b9b;
        }
    }
}

.thumbnail {
    width: 48px;
    height: 48px;
    border: 1px solid transparent;
    margin: 3px;
    padding: 3px;

    &.active {
        border-color: var(--blue);
        border-width: 2px;
        margin: 2px;
    }
}

p.product-price {
    font-size: 28px;
    font-weight: 600;
    letter-spacing: -0.05em;
    color: var(--black);
    margin-bottom: 16px;
    small {
        font-weight: 500;
        font-size: 16px;
        color: #727272;
        letter-spacing: normal;
    }
}

.small-price {
    font-size: 18px;
    font-weight: 700;
    letter-spacing: normal;
    color: var(--black);
    small {
        font-weight: 500;
        font-size: 13px;
        color: #727272;
        letter-spacing: normal;
    }
}

.product-sort {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    .sort {
        display: flex;
        align-items: center;
        .sort-label {
            margin-right: 8px;
        }
        .sort-select {
            margin-right: 8px;
        }
    }
}

ul.sort {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    li {
        margin-right: 12px;
        margin-bottom: 12px;
        a {
            display: inline-block;
            padding: 6px 12px;
            font-size: 14px;
            color: #fff;
            background: var(--blue);
            border-radius: 3px;
        }
        &.active {
            a {
                background: var(--gray);
            }
        }
    }
}

p.price {
    font-size: 21px;
    color: #d9147d;
    letter-spacing: -0.05em;
    font-weight: 900;
    small {
        font-weight: 300;
        font-size: 14px;
        color: #9b9b9b;
    }
}

.other-store-price {
    font-size: 13px;
    margin-top: 4px;
    padding: 3px 0;
    a {
        color: #646464;
        &:hover {
            color: #000;
        }
    }
}

.product-description {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid var(--nord4);
    /* Keep line breaks and tabs/spaces */
    white-space: pre-line;
    h4 {
        font-size: 18px;
        margin-bottom: 12px;
    }
    p {
        font-size: 14px;
        line-height: 1.4;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.amazon-p {
    margin: 24px 0 !important;
}

.amazon-p2 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.amazon-link {
    display: inline-block;
    padding: 6px 24px;
    font-size: 15px;
    color: #333 !important;
    background: #ffa41c;
    border-radius: 15px;
}

.amazon-text {
    color: #333;
    text-align: center;
    border: 3px solid #ffa41c;
    width: 100%;
    padding: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    &:hover {
        background: rgba(255, 153, 0, 0.1);
    }
}

.amazon-logo {
    margin-left: 8px;
    max-height: 48px;
    position: relative;
    top: 2px;

}

.product-text-list {
    list-style: none;
    margin: 0 0 16px;
    padding: 0;

    li {
        padding: 8px 4px;
        > div {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .thumb {
                aspect-ratio: 1;
                width: 48px;
                height: 48px;
                flex-grow: 0;
            }

            .main {
                flex-grow: 1;
                padding-left: 12px;
            }

            .props-list {
                list-style: none;
                margin: 0;
                padding: 0;
                li {
                    font-size: 14px;
                    display: inline-block;
                }
            }
        }
    }

    .price {
        // Never break
        white-space: nowrap;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: -0.05em;
        color: var(--black);
        small {
            font-weight: 500;
            font-size: 13px;
            color: #727272;
            letter-spacing: normal;
        }
    }

    .name {
        font-size: 16px;
        font-weight: 500;
        color: var(--blue-shade);

        // Max 1 line
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1; // Number of lines to show
        -webkit-box-orient: vertical;
    }

    .category {
        font-size: 14px;
        color: var(--nord1);
    }
}

.card {
    border: 1px solid var(--nord4);
    background-color: #fff;
    margin-bottom: 12px;
    position: relative;
    padding: 16px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.05);
    margin-top: 12px;
}

.hidden {
    display: none;
}

.toggle {
    font-size: 14px;
    color: var(--blue);
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

.button-like-paragraph {
    display: inline-block;
    padding: 6px 12px;
    font-size: 18px;
    border-radius: 3px;
    margin-bottom: 12px;
    border: 1px solid var(--nord10);
    background-color: var(--nord6);
}

.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.related {
    font-size: 12px;
    & ~ .related {
        margin-left: 5px;
    }
}

.main-image img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 3px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.05);
    border: 1px solid var(--nord4);
    margin-bottom: 8px;
    padding: 16px;
mix-blend-mode: multiply;

    &:hover {
        cursor: pointer;
        border-color: var(--blue);
    }
}

// A list of thumbnails
.gallery {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 items per row */
    max-width: 100%;
    column-gap: 8px;
    row-gap: 8px;
    margin-bottom: 16px;

    img {
        object-fit: contain;
        aspect-ratio: 1;
        display: block;
        border-radius: 3px;
        border: 1px solid var(--nord4);
        max-width: 100%;
        cursor: pointer;
        &:hover {
            border-color: var(--blue-tint);
        }
        &.active {
            border-color: var(--blue-tint);
        }
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999999;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    .modal-content {
        position: relative;
        width: 100%;
        max-width: 800px;
        max-height: 100%;
        background-color: #fff;
        border-radius: 3px;
        padding: 16px;
        overflow: auto;
        img {
            width: 100%;
            height: auto;
            display: block;
            margin-bottom: 8px;
        }
    }
    .close {
        position: absolute;
        top: 12px;
        right: 16px;
        padding: 16px;
        font-size: 32px;
        line-height: 32px;
        color: var(--white);
            cursor: pointer;
    }
}

.product-dates {
    p {
        font-size: 14px;
        margin: 0;
        color: var(--gray);
        &:last-child {
            margin-bottom: 16px;
        }
    }
}

.sort-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
        font-size: 14px;
        display: flex;
        align-items: center;
        svg {
            width: 16px;
            height: 16px;
            position: relative;
        }
    }
}

.sort-active {
    font-weight: bold;
}

.old-price {
    font-size: 14px;
    color: var(--gray);
    text-decoration: line-through;
}