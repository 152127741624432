.btn {
  display: inline-block;
  padding: 8px 24px;
  background-color: #D32F2F;
  color: var(--white);
  border: none;
  border-radius: 3px;
  &:hover {
    background-color: #C62828;
  }
  &.large {
    padding: 14px 42px;
    font-size: 16px;
  }
  small {
    opacity: .5;
  }

  img {
    max-height: 24px;
  position: relative;
    top: 6px
  }
}

a.cta {
  @extend .btn;
  padding: 6px 12px;
}

a.cta.small {
  font-size: 13px;
}

.btn-block {
    display: block;
    width: 100%;
  text-align: center;
}