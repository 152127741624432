img.fluid {
  width: 100%;
  height: auto;
  display: block;
  margin: 12px 0;
}

.video {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.img-responsive.mix {
  mix-blend-mode: multiply;
}

.img-caption {
  font-size: 12px;
  color: var(--meta);
}

.product-image {
  border-radius: 9px;
  mix-blend-mode: multiply;
}

// only show first .product-image
.large-img:not(:first-child) {
  display: none;
}