:root {
  --nord0: #2e3440;
  --nord1: #3b4252;
  --nord2: #434c5e;
  --nord3: #4c566a;
  --nord4: #d8dee9;
  --nord5: #e5e9f0;
  --nord6: #eceff4;
  --nord7: #8fbcbb;
  --nord8: #88c0d0;
  --nord9: #81a1c1;
  --nord10: #5e81ac;
  --nord11: #bf616a;
  --nord12: #d08770;
  --nord13: #ebcb8b;
  --nord14: #a3be8c;
  --nord15: #b48ead;

  --meta: #959ba5;
  --meta-light: #c1c6cf;

  --positive: #6ca278;
  --negative: #bf616a;

  --black-tint: #999999;
  --black: #1d1d1d;
  --black-shade: #161616;

  --white-tint: #fbfbfb;
  --white: #f7f7f7;
  --white-shade: #b9b9b9;

  --gray-tint: #c7cbce;
  --gray: #828c91;
  --gray-shade: #62696d;

  --blue-white: #f0f8fc;
  --blue-tint: #95d5ed;
  --blue: #14a1d8;
  --blue-shade: #0f79a2;

  --green-tint: #bddca6;
  --green: #6db13a;
  --green-shade: #52852c;

  --red-tint: #e7aca1;
  --red: #c9462d;
  --red-shade: #973522;

  --pink-tint: #fff3f0;
  --pink: #e5c1bd;
  --pink-shade: #ac918e;

  --orange-tint: #f7daaf;
  --orange: #eeac4e;
  --orange-shade: #b3813b;

  --yellow-tint: #f6efa0;
  --yellow: #eadb2c;
  --yellow-shade: #b0a421;

  --purple-tint: #E1BEE7;
  --purple: #AB47BC;
  --purple-shade: #7B1FA2;

  /* Black Friday Theme */
  --bf-primary-color: #1c1c1c; /* Classic Black: Represents the 'Black' in Black Friday, ideal for backgrounds or text. */
  --bf-secondary-color: #ff6e07; /* Vibrant Yellow: Catches attention and contrasts well with black. */
  --bf-accent-color: #E53935; /* Bold Red: Evokes urgency and excitement, perfect for highlighting deals or call-to-action buttons. */
  --bf-neutral-light: #FFFFFF; /* Pure White: For text or elements that need to stand out against dark backgrounds. */
  --bf-neutral-dark: #737373; /* Dark Grey: Provides a softer alternative to black for text or less prominent elements. */
  --bf-highlight-color: #4CAF50; /* Energetic Green: Great for signaling savings or eco-friendly products. */

  --bf-hover-secondary: #FFD54F
}