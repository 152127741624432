time[datetime].updated {
  border-bottom: 1px dotted var(--nord3);
  color: var(--nord3);
}

.page-title {
  font-size: 48px;
  font-weight: 300;
  margin-bottom: 0;
}

.subtitle {
  margin-top: 0;
  font-weight: 400;
  font-size: 21px;
  color: var(--meta);
}