
.main-navigation {
  list-style: none;
  margin: 12px 0 0 0;
  padding: 0;
  border: 1px solid var(--nord4);
  background-color: #fafafa;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.05);
}


.main-navigation li {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid var(--nord4);
}

.main-navigation li:last-child {
  border-bottom: none;
}

.main-navigation li.sub-header {
  text-transform: uppercase;
  border-bottom: 1px solid var(--nord4);
  color: var(--gray-shade);
  background-color: var(--white);
  font-weight:400;
  font-size: 15px;
  margin-bottom: 8px;
  padding-left: 12px;
  padding-bottom: 6px;
  padding-top: 12px;
  i {
    color: var(--gray-tint);
  }
}

.main-navigation li a {
  text-decoration: none;
  border-bottom: none;
  display: block;
  padding: 8px 4px 8px 22px;
  font-size: 14px;
  color: var(--gray-shade);
}

.main-navigation li.active {
  a {
    background-color: var(--blue-white);
    padding-left: 11px;
    &:before {
      position: relative;
      content:"• ";
      margin-right: 2px;
    }
  }
}


#sidebar-menu {
  position: fixed;
  top: 66px;
  bottom: 0;
  right: 0;
  z-index: 10000001;
  background-color: var(--white);
    border-left: 1px solid var(--nord4);
  width: 280px;
  transform: translateX(280px);
  transition: transform .1s, opacity .3s;
  opacity: 0;
  overflow: auto;
  &.expand {
    transform: translateX(0);
    opacity: 1;
  }
  > ul {
    list-style: none;
    padding: 0;
    margin: 0;
    > li {
      padding: 0;
      margin: 0;
      font-size: 16px;
      &.sub-header {
        font-size: 14px;
        padding: 6px 12px;
        text-transform: uppercase;
        font-weight: bold;
        color: var(--gray-shade);
        border-bottom: 1px solid var(--nord4);

      }
      > a {
        display: block;
        padding: 6px 12px;
        font-size: 14px;
      }
    }
  }
}

