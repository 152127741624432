.header {
  margin-bottom: 12px;
  color: var(--nord6);
  padding: 18px 12px;
  text-align: left;
  background-color: var(--blue);
  position: sticky;
  top: 0;
  z-index: 1000001;
  @media (min-width: 769px) {
    margin-bottom: 0;
  }
}

.header .brand {
  border-bottom: none;
  font-size: 18px;
  color: var(--nord6);
  line-height: 1.4;
  display: block;
  text-align: center;
  flex-grow: 1;

}

.header a {
  color: var(--nord6);
}

.header small {
  font-size: 12px;
  opacity: .8;
  color: var(--nord4);
}

.inner-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sub-navigation-header {
  border-bottom: 1px solid var(--nord4);
  margin-bottom: 16px;
  background: var(--blue-shade);
  ul {
    list-style: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    padding: 0;
    li {
        font-size: 14px;
      padding: 12px;
      a {
        color: var(--white);
        small {
          color: var(--meta);
        }
      }
      &:last-child > a {
        border: none;
      }
      & + li {
        margin-left: 16px;
      }
    }
  }
}