#gpdr {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .86);
  z-index: 10000001;
  display: flex;
  justify-content: center;
  align-items: center;

  .conscent {
    background-color: var(--nord6);
    max-width: 480px;
    min-width: 300px;
    margin-left: 16px;
    margin-right: 16px;
    color: var(--nord0);
    font-size: 14px;
    padding: 40px;
    h3 {
      margin-top: 0;
    }
  }
}