.breadcrumb ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.breadcrumb li {
  font-size: 14px;
}

.breadcrumb li a {
  color: var(--meta);
}

.breadcrumb li.active a {
  color: #78797e;
}

.breadcrumb li::before {
  content: "/";
  color: #ccc;
  margin: 0 6px;
}

.breadcrumb li:first-child::before {
  content: "";
  margin: 0;
}