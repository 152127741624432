.payment-options {
  display: flex;
  align-items: center;
  margin: 0 0 1rem 0;
  padding: 16px 0 0 0;
  list-style: none;
  justify-content: center;
}

.payment-icon {
  max-height: 18px;
}

.payment-icon:not(:last-child) {
  margin-right: 1rem;
}