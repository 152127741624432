.hit-item {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #444;

  .hit-name {
    // Max one line
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;

  }

    .hit-image {
        width: 100px;
        aspect-ratio: 1;
        object-fit: cover;
        margin-right: 1rem;
    }

    .hit-content {
        p {
            margin: 0;
        }
    }

  .hit-brand-name {
    color: #999;
  }

  .hit-price {
    font-size: 1.1rem;
    font-weight: 500;
    margin-top: .5rem !important;
    span {
      font-size: 0.9rem;
      font-weight: 400;
      color: #999;
    }
  }

  .hit-description {
    font-size: 0.9rem;
    color: #444;
    margin-top: .5rem !important;
  }
}

/*
<div class="ais-Stats"><span class="ais-Stats-text">21 results found in 3ms</span></div>
 */

.ais-Stats {
    margin: 1rem 0 0 0;
    font-size: 0.9rem;
    color: #999;
}

.ais-CurrentRefinements-list {
    display: flex;
    flex-wrap: wrap;
    margin: 1rem 0;
}

.ais-CurrentRefinements-item {
    margin: 0;
    padding: 0.5rem;
    background-color: #eee;
    border-radius: 0.5rem;
    font-size: 0.9rem;
    color: #444;
    cursor: pointer;
    &:hover {
        background-color: #ddd;
    }
}

.ais-Highlight-highlighted {
    color: #000;
    background-color: #d6e9ec;
}

.ais-RefinementList-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.ais-RefinementList-count {
     font-size: 0.6rem;
     color: #999;
}

#autocomplete {
  margin-top: 12px;
}


.aa-ItemContent {
  .hit-image img {
    aspect-ratio: 1;
    width: 62px;
    object-fit: cover;
    margin-right: 1rem;
  }

  .hit-small {
    font-size: 12px;
    opacity: .8;
  }
}

#autocomplete {
  min-height: 44px;
}