form {
  margin: 0;
}

input[type="text"], input[type="email"], input[type="password"] {
  border: 1px solid #ccc;
  outline: none;
  width: 100%;
  line-height: 34px;
  padding: 0 12px;
  font-size: 14px;
  &:focus {
    border: 1px solid #5c8d99;
    box-shadow: 0 0 1px rgba(#5c8d99, 1);
    background: #fff;
  }
}

input[type="checkbox"] {
  position: relative;
  top: 2px;
}

#newsletter {
  background: #f1f8fc;
  border: 1px solid #cae7f5;
  padding: 16px;
  margin: 16px 0;
  h3 {
    margin-top: 0;
  }

  .newsletter-more {
    display: none;
    font-size: 14px;
  }
}

.show {
  display: block !important;
}

.form-group {
  margin-bottom: 8px;
  font-size: 14px;
  label {
    font-size: 14px;
    font-weight: 500;
  }

  input ~ label {

    font-weight: 400;
  }
}

#show-more {
  border: 1px solid rgba(0, 0, 0, .2);
  padding: 16px;
}

.stack-form {
  > div {
    margin-bottom: 16px;
  }
}