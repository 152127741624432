*,
::before,
::after {
    box-sizing: border-box;
}

body {
    font-family: "Poppins", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
        Segoe UI Symbol;
    font-size: 1rem;
    line-height: 1.5;
    background-color: var(--nord6);
    color: var(--nord2);
    padding: 0;
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
    line-height: 1.1;
}

a {
    color: var(--blue-shade);
    text-decoration: none;
}

a:active {
    color: #017e98;
}

blockquote {
    display: block;
    border-width: 1px 0;
    border-style: solid;
    border-color: var(--meta);
    padding: 1.5em 0 0.5em;
    margin: 1.5em auto;
    position: relative;
    max-width: 480px;
    font-family: "Palatino", serif;
    font-size: 1.25em;
    font-style: italic;
    color: #333;
}

blockquote:before {
    content: "\201C";
    position: absolute;
    top: 0em;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #eee;
    width: 3rem;
    height: 2rem;
    font: 5em/0.8em "Palatino", serif;
    text-align: center;
    font-style: normal;
}

blockquote:after {
    content: attr(cite);
    display: block;
    text-align: right;
    font-size: 0.7em;
    color: var(--green);
    margin-top: 1em;
    font-style: normal;
}

mark {
    background-color: #ffc;
}

figure {
    display: inline-block;
    margin: 0 0 1rem;
}

figcaption {
    font-size: 90%;
    color: #868e96;
}

ul {
    padding-left: 17px;
}

.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}

@media (min-width: 1280px) {
    .container {
        width: 1258px;
    }
}

@media (min-width: 1400px) {
    .container {
        width: 1370px;
    }
}
@media (min-width: 1600px) {
    .container {
        width: 1570px;
    }
}
@media (min-width: 1900px) {
    .container {
        width: 1870px;
    }
}

.lead {
    font-weight: 300;
    font-size: 20px;
    line-height: 27px;
    strong {
        font-weight: 600;
    }
}

.content-block {
  position: relative;
    margin-top: 12px;
    border: 1px solid var(--nord4);
    padding: 0 20px;
    background-color: #fafafa;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.05);
}

hr {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, #f2f2f2, rgba(51, 51, 51, 0.2), #f2f2f2);
}

@media (max-width: 760px) {
    .container {
        padding-left: 8px;
        padding-right: 8px;
    }

    .content-block {
        padding: 0 16px;
    }
}

a[href^="https://bstd.se"], a[href^="https://amzn.to"]:not(.btn):not(.related-link):not(.no-amazon)
{
    color: #d64811;
}

#related-links {
    margin: 20px 0;
    font-size: 14px;
    padding: 0;
    border: 1px solid var(--nord4);
    border-radius: 3px;
    h4 {
        margin-top: 0;
        margin-bottom: 0;
        padding: 12px;
        border-bottom: 1px solid var(--nord4);
        background-color: var(--nord6);
    }
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            padding: 6px 12px;
            border-bottom: 1px solid var(--nord4);
            &:last-child {
                border: none;
            }

            small {
                color: var(--meta);
                float: right;
            }
        }
    }
}

.visible-phone {
    display: none;
    @media (max-width: 468px) {
        display: block;
    }
}

.visible-tablet {
    display: none;
    @media (max-width: 768px) {
        display: block;
    }
}

.desktop-only {
    display: none;
    @media (min-width: 769px) {
        display: block;
    }
}

.content-inject,
.below-footer {
    width: 100%;
    min-height: 200px;
    height: 250px;
    text-align: center;
}

.truncate {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

ul.list {
    list-style: none;
    padding: 0;
    margin: 16px 0;
    border: 1px solid var(--nord5);
    background-color: var(--white-tint);
    > li {
        font-size: 14px;
        > a {
            padding: 8px;
            display: block;
            border-bottom: 1px solid var(--nord5);
            small {
                color: var(--meta);
            }
        }
        &:last-child > a {
            border: none;
        }
    }
}

.modal-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.45);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20000001;
    &:after {
        position: absolute;
        content: "Stäng med (ESC) eller klick utanför bild";
        color: #fff;
        top: 12px;
        right: 12px;
    }

    img {
        max-width: 90%;
        max-height: 85%;
        height: auto;
        border-radius: 3px;
        box-shadow: 0 0 18px rgba(0, 0, 0, 0.75);
    }
}

.strike-through {
    text-decoration: line-through;
    opacity: 0.75;
    font-size: 13px;
}

.sidebar-box {
    margin-bottom: 20px;
    border: 1px solid #B2DFDB;
    border-radius: 3px;
    padding: 20px;
    background-color: #E0F2F1;
    color: #00695C;
}

.clamp {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    &.clamp--expanded {
        -webkit-line-clamp: unset;
    }
}

/* a lot of margin helper classes m1 - m6, mt1 - mt6 etc in scss loop */
@for $i from 1 through 6 {
    .m#{$i} {
        margin: #{$i * 4}px !important;
    }
    .mt#{$i} {
        margin-top: #{$i * 4}px !important;
    }
    .mr#{$i} {
        margin-right: #{$i * 4}px !important;
    }
    .mb#{$i} {
        margin-bottom: #{$i * 4}px !important;
    }
    .ml#{$i} {
        margin-left: #{$i * 4}px !important;
    }
}
